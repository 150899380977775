/* Main Container Styles */
.profile-page {
    padding: 20px;
    margin-left: 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.verify-badge-small{
    height: 30px;
    width: 30px;
}
/* Header Section Styles */
.profile-header-section {
    margin-bottom: 30px;
    position: relative;
}

.profile-cover-photo {
    height: 200px;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    border-radius: 15px 15px 0 0;
    position: relative;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

/* Main Profile Card */
.profile-main-card {
    margin-top: -70px;
    margin-left: 20px;
    margin-right: 20px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: white;
}

/* Profile Photo Styles */
.profile-photo-container {
    position: relative;
    margin-top: -50px;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    border: 4px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

/* Profile Info Styles */
.profile-info {
    padding: 20px 0;
}

.profile-info h2 {
    margin: 0;
    color: #2d3436;
    font-weight: 600;
}

.email-info {
    color: #636e72;
    margin: 5px 0;
}

/* Stats Section */
.profile-stats {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}

.stat-item {
    text-align: center;
}

.stat-value {
    font-size: 1.5em;
    font-weight: 600;
    color: #2d3436;
}

.stat-label {
    color: #636e72;
    font-size: 0.9em;
}

/* Action Buttons */
.profile-actions {
    display: flex;
    gap: 10px;
    padding: 20px 0;
}

.action-button {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: #764ba2;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Post Section */
.post-section {
    margin-top: 40px;
}

.post {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.post-header {
    display: flex;
    align-items: center;
    gap: 15px;
}

.post-profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.post-caption {
    margin: 15px 0;
    font-size: 1em;
    color: #34495e;
}

/* Post Media Styles */
.media-collage {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.collage-item {
    flex: 1;
    height: 450px;
    border-radius: 15px;
    object-fit: cover;
}

/* Post Actions */
.post-actions {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    align-items: center;
}

/* Button Styles for Actions */
.like-button, .comment-button, .share-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: background-color 0.3s ease;
}

.like-button {
    background-color: #2ecc71; /* Green */
}

.like-button:hover {
    background-color: #27ae60; /* Darker green */
}

.comment-button {
    background-color: #3498db; /* Blue */
}

.comment-button:hover {
    background-color: #2980b9; /* Darker blue */
}

.share-button {
    background-color: #f39c12; /* Orange */
}

.share-button:hover {
    background-color: #e67e22; /* Darker orange */
}

/* Likes Count */
.likes-count {
    color: #636e72;
    font-size: 0.9em;
}
