.pending-ads-container {
    max-width: 800px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add some padding */
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.pending-ads-container h1 {
    text-align: center; /* Center the heading */
    color: #333; /* Dark text color for contrast */
    font-size: 2rem; /* Larger font size */
    margin-bottom: 20px; /* Space below the heading */
}
img{
    border-radius: 15px;
}

.pending-ads-container ul {
    list-style-type: none; /* Remove bullet points from the list */
    padding: 0; /* Remove default padding */
}

.pending-ads-container li {
    background-color: #fff; /* White background for each ad */
    margin-bottom: 15px; /* Space between each ad */
    padding: 15px; /* Padding inside each ad */
    border-radius: 6px; /* Rounded corners for each ad */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for each ad */
}

.pending-ads-container li h2 {
    color: #007bff; /* Bootstrap primary color for titles */
    margin: 0; /* Remove default margin */
}

.pending-ads-container li p {
    margin: 10px 0; /* Space above and below each paragraph */
    color: #666; /* Gray color for description */
}

.buttons {
    display: flex; /* Use flexbox for button layout */
    justify-content: space-between; /* Space between buttons */
}

button {
    padding: 10px 15px; /* Padding for buttons */
    font-size: 1rem; /* Font size for buttons */
    color: #fff; /* White text color */
    background-color: #007bff; /* Bootstrap primary color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners for buttons */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

button:disabled {
    background-color: #ccc; /* Gray color for disabled buttons */
    cursor: not-allowed; /* Not-allowed cursor for disabled buttons */
}
