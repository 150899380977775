.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #24292e;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  z-index: 1000;
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.sidebar-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.sidebar h3 {
  color: #f8f9fa;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.sidebar-links {
  list-style-type: none;
  padding: 0;
}

.sidebar-links li {
  margin-bottom: 20px;
}

.sidebar-links li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar-links li a:hover {
  background-color: #3d3d3d;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-footer {
  padding: 20px 0 0;
  margin-top: auto; /* Ensures logout button stays at the bottom */
}

.logout-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.logout-button:hover {
  background-color: #c9302c;
}
