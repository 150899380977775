/* Reports.css */

.reports-page {
    padding: 20px;
    background-color: #f8f9fa; /* Light background color */
    min-height: calc(100vh - 70px); /* Adjust based on your layout */
  }
  
  .reports-page h2 {
    color: #343a40; /* Dark gray heading color */
  }
  
  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust as needed */
  }
  
  .reports-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px;
  }
  
  .report-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .report-header p {
    margin: 0;
  }
  
  .report-body {
    margin-bottom: 10px;
  }
  
  .report-body p {
    margin: 5px 0;
  }
  
  .report-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Optional: Style for resolved reports */
  .report-card.resolved {
    background-color: #e9ecef; /* Lighter background for resolved reports */
  }
  