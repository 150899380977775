.main-container {
  margin-left: 250px; /* Same width as sidebar to push the content */
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh; /* Make sure it takes full height */
  box-sizing: border-box;
  overflow-y: auto;
}

.main-container h1 {
  font-size: 32px;
  font-weight: bold;
}
