.dashboard {
    padding: 20px;
    background: #f4f7fb;
    min-height: 100vh;
    margin-left: 80px;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    background: #1d72b8;
    padding: 20px;
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-controls .date {
    font-size: 18px;
  }
  
  .push-notification-btn {
    background-color: #ff5c5c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .push-notification-btn:hover {
    background-color: #ff4040;
  }
  
  .dashboard-panels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Individual Panels */
  .panel {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  h2{
    color: #f4f7fb;
  }
  
  .panel:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .panel-header h3 {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .panel-icon {
    font-size: 24px;
    color: #1d72b8;
  }
  
  .performance-panel .panel-body h1 {
    font-size: 48px;
    margin: 0;
  }
  
  .performance-panel .panel-body p {
    font-size: 16px;
    color: #666;
  }
  
  .performance-stats {
    margin-top: 10px;
  }
  
  .performance-stats .stat {
    display: inline-block;
    margin-right: 15px;
    color: #666;
  }
  
  .up-arrow {
    color: green;
    margin-right: 5px;
  }
  
  .down-arrow {
    color: red;
    margin-right: 5px;
  }
  
  /* Trending Topics */
  .trending-panel .panel-body {
    padding: 10px;
  }
  
  .trending-list {
    list-style: none;
    padding: 0;
  }
  
  .trending-list li {
    padding: 10px;
    background: #f4f7fb;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .trending-list li:hover {
    background-color: #e3f1fc;
    color: #1d72b8;
  }
  
  /* Chart Panel */
  .chart-panel .panel-body {
    padding: 10px 0;
  }
  .dashboard-panels {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Optional spacing between panels */
  }
  
  .panel {
    flex: 1; /* Allows panels to grow */
    min-width: 250px; /* Minimum width for panels */
    max-width: 400px; /* Adjust as necessary */
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .panel-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .panel-body {
    padding: 15px;
  }
  
  .reports-panel {
    flex-basis: 100%; /* Makes this panel take full width */
  }
  
  .full-width {
    flex-basis: 100%; /* Forces the element to take full width */
  }
    

  .dashboard-controls {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between elements */
  }
  
  .search-bar {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px; /* Set the width of the search bar */
    transition: border-color 0.3s;
  }
  
  .search-bar:focus {
    outline: none;
    border-color: #1d72b8; /* Change border color on focus */
  }
  
  .push-notification-btn {
    background-color: #1d72b8; /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .push-notification-btn:hover {
    background-color: #155a8a; /* Darker shade on hover */
  }
  

/* pop up css*/
/* Dashboard.css */

/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 400px;
  }
  
  .popup-content h3 {
    margin: 0 0 15px;
  }
  
  .popup-content form {
    display: flex;
    flex-direction: column;
  }
  
  .popup-content label {
    margin-bottom: 10px;
  }
  
  .popup-content input,
  .popup-content select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .popup-content button {
    margin-top: 10px;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background: #0056b3;
  }
  