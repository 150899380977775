/* Users.css */
.users-page {
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
  }
  
  h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 20px;
  }
  
  .search-bar input {
    padding: 10px;
    width: 100% -300px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-buttons button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .filter-buttons button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .users-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    padding: 10px;
  }
  
  .user-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .user-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .user-info h3 {
    font-size: 1.2em;
    margin: 0;
  }
  
  .user-info p {
    color: #555;
    margin: 5px 0;
  }

  /* Loading Indicator */
.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
  }

  
  .search-input:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none;
  }