/* Post.css */

/* General styles */
.post-detail-page {
    max-width: 700px; /* Increased width for a spacious layout */
    margin: 20px auto;
    padding: 20px;
    background: #f8f9fa; /* Light grey background for contrast */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Clean font */
  }
  
  /* Title styles */
  h3 {
    text-align: center;
    color: #343a40; /* Darker text for contrast */
    margin-bottom: 20px;
  }
  
  /* Loading indicator */
  .loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  /* Filter Tabs */
  .filter-tabs {
    display: flex; /* Flexbox for a horizontal layout */
    justify-content: space-between; /* Space between tabs */
    margin-bottom: 20px;
  }
  
  .filter-tabs .nav-link {
    flex: 1; /* Allow links to grow evenly */
    text-align: center; /* Center text */
    background-color: #007bff; /* Primary color */
    color: white; /* White text */
    padding: 12px; /* Padding for better touch area */
    border-radius: 8px; /* Rounded corners */
    margin: 0 5px; /* Margin between tabs */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .filter-tabs .nav-link:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Active tab styles */
  .filter-tabs .nav-link.active {
    background-color: #0056b3; /* Darker shade for active tab */
    font-weight: bold; /* Bold text for emphasis */
  }
  
  /* Post item styles */
  .post-item {
    margin-bottom: 20px;
    padding: 15px;
    background: #fff; /* White background for posts */
    border: 1px solid #e1e1e1; /* Light border */
    border-radius: 10px; /* Rounded corners */
    transition: box-shadow 0.2s; /* Smooth shadow transition */
  }
  
  .post-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  /* Post header styles */
  .post-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .post-profile-pic {
    width: 55px; /* Slightly larger profile picture */
    height: 55px;
    border-radius: 50%; /* Circular profile picture */
    margin-right: 10px;
    border: 2px solid #007bff; /* Blue border */
  }
  
  .post-header h4 {
    margin: 0;
    color: #007bff; /* Consistent color with tabs */
  }
  
  .post-header p {
    margin: 0;
    font-size: 13px; /* Smaller font for additional info */
    color: #6c757d; /* Muted grey color */
  }
  
  /* Media collage styles */
  .media-collage {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    margin: 10px 0;
  }
  
  .collage-item {
    position: relative; /* Position for hover effect */
    overflow: hidden; /* Crop overflow for rounded corners */
    margin: 5px; /* Margin for spacing */
    border-radius: 8px; /* Rounded corners */
    transition: transform 0.2s ease; /* Smooth transform transition */
    flex: 1 1 calc(33.33% - 10px); /* Three items per row with flexible width */
  }
  
  .collage-item img {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Block element */
  }
  
  /* Hover effect for collage items */
  .collage-item:hover {
    transform: scale(1.1); /* Scale up the image on hover */
    z-index: 1; /* Bring hovered item to the front */
  }
  
  /* Post details styles */
  .post-details {
    margin: 10px 0;
    font-size: 14px;
    color: #495057; /* Darker grey for text */
  }
  
  .post-details p {
    margin: 5px 0; /* Margin for spacing */
  }
  
  /* Admin actions */
  .admin-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .admin-actions button {
    flex: 1; /* Buttons grow to fill space */
    margin: 0 5px; /* Space between buttons */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .admin-actions button:hover {
    background-color: #f1c40f; /* Highlight color on hover */
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .post-item {
      padding: 10px; /* Adjust padding for small screens */
    }
  
    .media-collage {
      justify-content: center; /* Center collage on small screens */
    }
  
    .collage-item {
      flex: 1 1 calc(50% - 10px); /* Two items per row on small screens */
    }
  
    .admin-actions {
      flex-direction: column; /* Stack buttons on small screens */
    }
  
    .admin-actions button {
      width: 100%; /* Full width buttons */
      margin-bottom: 10px; /* Space between buttons */
    }
  }
/* Post.css */

/* Collapsible Button Styles */
.collapsible-section {
    margin: 10px 0; /* Space around collapsible sections */
  }
  
  .collapsible-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px; /* Padding for button */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .collapsible-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Post Details Styles */
  .category-list,
  .city-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
    margin: 5px 0; /* Margin for spacing */
  }
  
  .category-list li,
  .city-list li {
    background: #e9ecef; /* Light grey background for items */
    border-radius: 5px; /* Rounded corners for items */
    padding: 8px; /* Padding for better touch area */
    margin-bottom: 5px; /* Space between items */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .category-list li:hover,
  .city-list li:hover {
    background-color: #007bff; /* Change color on hover */
    color: white; /* White text on hover */
  }
  