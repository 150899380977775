/* Main Container Styles */
.profile-page {
    padding: 20px;
    margin-left: 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

/* Header Section Styles */
.profile-header-section {
    margin-bottom: 30px;
    position: relative;
}

.profile-cover-photo {
    height: 200px;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    border-radius: 15px 15px 0 0;
    position: relative;
    overflow: hidden;
}

.profile-cover-photo img {
    height: 200px;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    border-radius: 15px 15px 0 0;
    position: relative;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

/* Main Profile Card */
.profile-main-card {
    margin-top: -70px;
    margin-left: 20px;
    margin-right: 20px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: white;
}

/* Profile Photo Styles */
.profile-photo-container {
    position: relative;
    margin-top: -50px;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    border: 4px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

/* Profile Info Styles */
.profile-info {
    padding: 20px 0;
}

.profile-info h2 {
    margin: 0;
    color: #2d3436;
    font-weight: 600;
    color: #2d3436;
    font-weight: 600;
    text-align: left;
}

.business-type {
    color: #636e72;
    font-size: 1.1em;
    margin: 5px 0 15px 0;
}

/* Stats Section */
.profile-stats {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}

.stat-item {
    text-align: center;
}

.stat-value {
    display: block;
    font-size: 1.5em;
    font-weight: 600;
    color: #2d3436;
}

.stat-label {
    color: #636e72;
    font-size: 0.9em;
}

/* Action Buttons */
.profile-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
}

.action-button {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease;
}

.action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Info Cards */
.info-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.info-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.info-card .card-header {
    background-color: white;
    border-bottom: 1px solid #f1f1f1; padding: 15px;
    border-radius: 15px 15px 0 0;
}

.info-card .card-body {
    padding: 20px;
}

/* Modal Styles */
.modal-header {
    border-bottom: none;
    padding: 15px;
    background-color: #f8f9fa;
}

.modal-body {
    padding: 20px;
}

.modal-footer {
    border-top: none;
    padding: 15px;
    background-color: #f8f9fa;
}

.modal-footer button {
    margin: 0 10px;
}